import React from "react";

import Grid2 from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';

import PropTypes from 'prop-types';

export class OutlinedTitledBox extends React.Component {
	static propTypes = {
		"children": PropTypes.node,
		"title": PropTypes.string.isRequired
	};

	render() {
		return (
			<Grid2 xs={4} container alignItems="center" justifyContent="center" style={{display: "contents"}}>
				<Paper xs={12} style={{minWidth: "220px"}} variant="outlined" component="fieldset">
					<legend style={{display: "inline-flex"}}>{this.props.title}</legend>
					{this.props.children}
				</Paper>
			</Grid2>
		);
	}
}
