import React from "react";

import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

import {PlayerCard} from "./PlayerCard";

TeamSheet.propTypes = {
	"disableEdition": PropTypes.bool.isRequired,
	"disableNameEdition": PropTypes.bool.isRequired,
	"disableNewPlayer": PropTypes.bool.isRequired,
	"handleAddNewPlayer": PropTypes.func.isRequired,
	"handleNameChange": PropTypes.func.isRequired,
	"handlePlayerChange": PropTypes.func.isRequired,
	"name": PropTypes.string.isRequired,
	"players": PropTypes.arrayOf(PropTypes.object).isRequired,
};
function TeamSheet(props) {
	const nums = props.players.map((player) => player.numero).concat([0,0]);
	const duplicatedNums = nums.filter((num, index) => index !== nums.indexOf(num));
	return (
		<>
			<Grid2
				container
				justifyContent="center"
				margin={1}
				padding={0}
				sx={{"minHeight": {"xs":"3em", "md":0}}}
				xs={12}
			>
				{props.disableNameEdition
					? <Typography
						style={{
							"color": "#64DCBE",
							"fontSize": "large",
							"fontWeight": "bold",
							"textAlign": "center",
						}}
					>
						{props.name}
					</Typography>
					: <TextField
						disabled={props.disableEdition}
						error={props.name === ""}
						inputProps={{ "sx": { "textAlign": "center" } }}
						label="Nom d'équipe"
						onChange={props.handleNameChange}
						value={props.name}
						variant="outlined"
					/>
				}
			</Grid2>
			<Grid2 justifyContent="center" container margin={0.5} padding={0} xs={12}>
				Joueurs
			</Grid2>
			{props.players.map((player, index) => (
				<Grid2 justifyContent="center" container key={player.id} margin={0.5} padding={0} xs={12}>
					<PlayerCard
						disabled={props.disableEdition}
						index={index}
						key={player.id}
						numError={duplicatedNums.includes(player.numero)}
						onChange={props.handlePlayerChange}
						player={player}
					/>
				</Grid2>
			))}
			{(props.disableEdition || props.disableNewPlayer) || (
				<Grid2 justifyContent="center" container margin={0.5} padding={0} xs={12}>
					<Button
						onClick={props.handleAddNewPlayer}
						sx={{
							"backgroundColor": "rgba(255, 255, 255, 0.05)",
							"height": {"xs": "150px", "md": "85px"},
							"maxWidth": {"xs": "300px", "md": "450px"},
							"width": "100%"
						}}
						variant="contained"
					>
						<AddIcon fontSize="large"/>
					</Button>
				</Grid2>
			)}
		</>
	);
}

export default TeamSheet;
