import React from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid2 from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

import { DeleteWithSpinnerIcon } from "./DeleteWithSpinnerIcon";

export class PlayerCard extends React.Component {
	static propTypes = {
		"disabled": PropTypes.bool.isRequired,
		"index": PropTypes.number.isRequired,
		"numError": PropTypes.bool.isRequired,
		"onChange": PropTypes.func.isRequired,
		"player": PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleNumChange = this.handleNumChange.bind(this);

		this.state = {
			"disableDelete": false,
		}
		this.deleteRef = React.createRef();
	}

	handleDelete() {
		this.props.onChange(this.props.index, this.props.player, true);
	}

	handleNameChange(event) {
		const player = this.props.player;
		player.nom = event.target.value;
		this.props.onChange(this.props.index, player);
	}

	handleNumChange(event) {
		let num = 0;
		if (event.target.value !== "") {
			num = parseInt(event.target.value, 10);
			if (isNaN(num) || num < 0) {
				return;
			}
		}
		const player = this.props.player;
		player.numero = num;
		this.props.onChange(this.props.index, player);
	}

	render() {
		return (
			<Card
				elevation={5}
				sx={{
					"backgroundColor": "rgba(255, 255, 255, 0.1)",
					"height": {"xs": "150px", "md": "85px"},
					"maxWidth": {"xs": "300px", "md": "450px"},
					"width": "100%"
				}}
			>
				<CardContent>
					<TextField
						disabled={this.props.disabled}
						error={this.props.numError}
						inputProps={{
							"inputMode": "numeric",
							"sx": {"textAlign": "center"},
						}}
						label="Numéro"
						onChange={this.handleNumChange}
						size="small"
						sx={{
							"maxWidth": {"md": "100px"},
							"paddingRight": "16px",
							"width": "calc(100% - 45px)",
						}}
						value={this.props.player.numero || ""}
						variant="standard"
					/>
					<TextField
						disabled={this.props.disabled}
						inputProps={{"sx": {"textAlign": "left"}}}
						label="Nom du joueur"
						onChange={this.handleNameChange}
						size="small"
						sx={{
							"display": {"xs": "none", "md": "inline-flex"},
							"paddingRight": "16px",
							"width": "calc(100% - 145px)",
						}}
						value={this.props.player.nom}
						variant="standard"
					/>
					<Button
						disabled={this.state.disableDelete || this.props.disabled}
						disableElevation
						key={this.props.player.id}
						sx={{
							"backgroundColor": "rgba(255, 255, 255, 0.1)",
							"height": "45px",
							"minWidth": "45px",
							"padding": 0,
						}}
						variant="contained"
						onClick={()=>{
							this.setState({"disableDelete": true});
							this.deleteRef.current.onClick();
						}}
					>
						<DeleteWithSpinnerIcon
							deleteFunc={this.handleDelete}
							key={this.props.player.id}
							ref={this.deleteRef}
							tooltip="Supprimer le Joueur"
						/>
					</Button>
					<Grid2 sx={{"display": {"md": "none"}, "width": "100%"}}/>
					<TextField
						disabled={this.props.disabled}
						inputProps={{"sx": {"textAlign": "left"}}}
						label="Nom du joueur"
						onChange={this.handleNameChange}
						size="small"
						sx={{"display": {"md": "none"}, "width": "100%"}}
						value={this.props.player.nom}
						variant="standard"
					/>
				</CardContent>
			</Card>
		);
	}
}
