
import React from 'react';

import AppBar from '@mui/material/AppBar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import {HomeLogo} from "./HomeLogo";

export class HeaderBar extends React.Component {
	static propTypes = {
		"codeRencontre": PropTypes.string,
		"disableHomeLogo": PropTypes.bool.isRequired,
		"sport": PropTypes.string
	};

	render() {
		return (
			<>
				<AppBar position="fixed" sx={{"zIndex": (theme) => theme.zIndex.drawer + 1}}>
					<Toolbar>
						<HomeLogo disabled={this.props.disableHomeLogo} />
						{(this.props.sport && this.props.codeRencontre) ? (
							<Breadcrumbs sx={{"mx": "1em"}}>
								<Typography>
									{this.props.sport.replace(/^./, (str) => str.toUpperCase())}
								</Typography>
								<Typography color="text.primary">
									{this.props.codeRencontre}
								</Typography>
							</Breadcrumbs>
						) : (
							""
						)}
					</Toolbar>
				</AppBar>
			</>
		);
	}
}
