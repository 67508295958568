import React from "react";

import Avatar from "@mui/material/Avatar";
import GroupsIcon from "@mui/icons-material/Groups";
import Tooltip from "@mui/material/Tooltip";

import PropTypes from "prop-types";

export class PlayerAvatar extends React.Component {
	static propTypes = {
		"name": PropTypes.string,
		"num": PropTypes.number.isRequired,
		"selected": PropTypes.bool.isRequired,
		"selectPlayer": PropTypes.func.isRequired,
		"type": PropTypes.string.isRequired
	};

	render() {
		return (
			<Tooltip title={this.props.name || "numéro "+this.props.num}>
				<Avatar
					sx={{"bgcolor": this.props.selected ? "#64DCBE" : "#FFFFFF"}}
					onClick={() => this.props.selectPlayer(this.props.num)}
				>
					{(this.props.type === "team")
						? <GroupsIcon/>
						: this.props.num
					}
				</Avatar>
			</Tooltip>
		);
	}
}
