import React from "react";

import Grid2 from "@mui/material/Unstable_Grid2";

import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import {PlayerAvatar} from "./PlayerAvatar";

TeamSelector.propTypes = {
	"handlePlayerSelect": PropTypes.func.isRequired,
	"name": PropTypes.string.isRequired,
	"nbStartPlayers": PropTypes.number.isRequired,
	"players": PropTypes.arrayOf(PropTypes.object).isRequired,
	"selectedPlayerNum": PropTypes.number.isRequired,
};
function TeamSelector(props) {
	return (
		<>
			<Grid2 container justifyContent="center" xs={12}>
				{props.name}
			</Grid2>
			<Grid2 xs={12} container justifyContent="center">
				{(props.nbStartPlayers > 1 || props.players.length === 0) &&
					<PlayerAvatar
						num={-1}
						name={props.name}
						selected={props.selectedPlayerNum === -1}
						selectPlayer={props.handlePlayerSelect}
						type="team"
					/>
				}
				{props.nbStartPlayers === 1 &&
					<Typography align="center" noWrap={true} sx={{fontStyle: "italic", fontSize: "smaller"}}>
						{props.players[0]?.nom}
					</Typography>
				}
			</Grid2>
			{[...props.players].filter((p) => p.numero !== 0).sort((a, b) => a.numero - b.numero).map((player, index) => (
				<Grid2 key={index} xs={6} container justifyContent="center">
					<PlayerAvatar
						num={player.numero}
						name={player.nom}
						selected={props.selectedPlayerNum === player.numero}
						selectPlayer={props.handlePlayerSelect}
						type="player"
					/>
				</Grid2>
			))}
		</>
	);
}

export default TeamSelector;
