import React from "react";

import Grid from '@mui/material/Unstable_Grid2';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {format} from "date-fns";
import PropTypes from "prop-types";

import {DeleteWithSpinnerIcon} from "./DeleteWithSpinnerIcon"

export class EventsTable extends React.Component {
	static propTypes = {
		"deleteEventFunc": PropTypes.func.isRequired,
		"events": PropTypes.arrayOf(PropTypes.object).isRequired,
		"restrictDelete": PropTypes.bool
	};

	render() {
		return (
			<TableContainer component={Paper}>
				<Table padding="none" sx={{"tableLayout": "fixed"}}>
					<TableHead sx={{
						"backgroundColor": "#121212",
						"display": "inline-table",
						"height": "30px",
						"width": "100%"
					}}>
						<TableRow>
							<TableCell align="center" colSpan={3}>
								Évènements enregistrés
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody sx={{
						"display": "block",
						"maxHeight": "200px",
						"overflowY": "scroll",
						"width": "100%"
					}}>
						{this.props.events.sort((a, b) => (b.timestamp - a.timestamp)).map((event, index) => (
							<TableRow key={index} sx={{
								"backgroundColor": index % 2 ? "#121212" : "rgba(255, 255, 255, 0.12)",
								"display": "inline-table",
								"height": "30px",
								"width": "100%",
							}}>
								<TableCell align="center" width="100px">
									{format(new Date(event.timestamp), "HH'H'mm ss's'")}
								</TableCell>
								<TableCell align="left" sx={{"py": "4px"}}>
									{event.action.description}
								</TableCell>
								<TableCell align="center" width="50px">
									<Grid display="flex" sx={{"justifyContent": "center"}}>
										<DeleteWithSpinnerIcon
											deleteFunc={() => this.props.deleteEventFunc(event.elastic_id)}
											hide={index > 0 && (this.props.restrictDelete || event.action.type === "match_start")}
											tooltip="Supprimer l'évènement"
										/>
									</Grid>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}
}
