import React from "react";

import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

export class DeleteWithSpinnerIcon extends React.Component {
	static propTypes = {
		"deleteFunc": PropTypes.func.isRequired,
		"hide": PropTypes.bool,
		"tooltip": PropTypes.string,
	};

	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.state = {
			"isSpinning": false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.deleteFunc !== this.props.deleteFunc) {
			this.setState({"isSpinning": false});
		}
	}

	onClick() {
		this.setState({"isSpinning": true});
		this.props.deleteFunc();
	}

	render() {
		if (this.props.hide) {
			return "";
		}
		if (this.state.isSpinning) {
			return (
				<CircularProgress disableShrink size={20} />
			);
		}
		return (
			<Tooltip sx={{"cursor": "pointer"}} title={this.props.tooltip}>
				<DeleteIcon onClick={this.onClick} />
			</Tooltip>
		);
	}
}
