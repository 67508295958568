import React from "react";

import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Grid2 from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Paper from "@mui/material/Paper";
import QrCodeIcon from '@mui/icons-material/QrCode';
import Snackbar from '@mui/material/Snackbar';
import SvgIcon from '@mui/material/SvgIcon';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import PropTypes from "prop-types";

export class InformationsTable extends React.Component {
	static propTypes = {
		"informations": PropTypes.arrayOf(PropTypes.object).isRequired
	};

	constructor(props) {
		super(props);
		this.copyToClipboard = this.copyToClipboard.bind(this);
		this.state = {
			"copySnackIsOpen": false,
			"qrCodeIsOpen": false,
			"qrCodeUrl": ""
		};
	}

	copyToClipboard(text) {
		navigator.clipboard.writeText(text);
		this.setState({"copySnackIsOpen": true});
	}

	render() {
		const iconWithAction = (component, tooltip, onClick) => (
			<Tooltip disableInteractive title={tooltip}>
				<SvgIcon
					component={component}
					fontSize="small"
					onClick={onClick}
					sx={{"cursor": "pointer"}}
				/>
			</Tooltip>
		);
		return (<>
			<Backdrop
				onClick={() => this.setState({"qrCodeIsOpen": false})}
				open={this.state.qrCodeIsOpen}
				sx={{"zIndex": (theme) => theme.zIndex.drawer+1}}
			>
				<CloseIcon fontSize="large" sx={{
					"cursor": "pointer",
					"position": "absolute",
					"right": "15px",
					"top": "15px"
				}}/>
				<img alt="QR Code" src={this.state.qrCodeUrl} style={{
					"maxHeight": "min(400px, calc(100% - 10px))",
					"maxWidth": "min(400px, calc(100% - 10px))"
				}}/>
			</Backdrop>
			<TableContainer component={Paper}>
				<Table>
					<TableBody>
						{this.props.informations.map(({description, url, qrCodeUrl}, index) => (
							<TableRow key={index}>
								<TableCell>{description}</TableCell>
								<TableCell sx={{"display": {"xs": "none", "sm": "table-cell"}}}>
									<Link
										href={url}
										sx={{"wordBreak": "break-all"}}
										target="_blank"
										underline="hover"
									>{url}</Link>
								</TableCell>
								<TableCell align="center" sx={{"whiteSpace":"noWrap"}}>
									<Grid2 sx={{"display": {"xs": "contents", "sm": "none"}}}>
										{iconWithAction(
											OpenInNewIcon,
											"Ouvrir dans un nouvel onglet",
											() => window.open(url, '_blank', 'noopener,noreferrer')
										)}
									</Grid2>
									&nbsp;
									{iconWithAction(
										ContentCopyIcon,
										"Copier l'URL",
										() => this.copyToClipboard(url)
									)}
									&nbsp;
									{iconWithAction(
										QrCodeIcon,
										"Afficher le QRCode",
										() => this.setState({"qrCodeIsOpen": true, "qrCodeUrl": qrCodeUrl})
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Snackbar
				anchorOrigin={{"horizontal": "center", "vertical": "bottom"}}
				autoHideDuration={5000}
				onClose={() => this.setState({"copySnackIsOpen": false})}
				open={this.state.copySnackIsOpen}
			>
				<Alert severity="info" variant="filled">Copié</Alert>
			</Snackbar>
		</>);
	}
}
