import React from 'react';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';

import PropTypes from 'prop-types';

export class TabsNavigationBar extends React.Component {
	static propTypes = {
		"selectedTab": PropTypes.string.isRequired,
		"setSelectedTab": PropTypes.func.isRequired,
		"tabs": PropTypes.arrayOf(PropTypes.object).isRequired
	};

	constructor(props) {
		super(props);
		this.setSelectedTab = this.setSelectedTab.bind(this);
		this.state = {
			"drawerIsOpen": true
		};
	}

	componentDidMount() {
		let newId = this.props.tabs[0].id;
		this.props.tabs.forEach(({id}) => {
			if (window.location.hash === "#"+id) {
				newId = id;
			}
		});
		this.setSelectedTab(newId);
	}

	setSelectedTab(id) {
		this.props.setSelectedTab(id);
		window.location.replace("#"+id);
	}

	render() {
		const drawerWidth = (this.state.drawerIsOpen ? "200px" : "56px");
		return (
			<>
				<Box display="flex" sx={{"minHeight": "100%"}}>
					<Drawer
						PaperProps={{"sx": {"overflow": "hidden", "width": drawerWidth}}}
						sx={{
							"display": {"xs": "none", "sm": "block"},
							"flexShrink": 0,
							"width": drawerWidth,
							"zIndex": (theme) => theme.zIndex.drawer
						}}
						variant="permanent"
					>
						<Toolbar />
						<List sx={{"padding": 0}}>
							<ListItem disablePadding sx={{"display": "block"}}>
								<ListItemButton
									onClick={() => this.setState({"drawerIsOpen": !this.state.drawerIsOpen})}
									sx={{
										"minHeight": 48,
										"px": 2.5,
										"justifyContent": (this.state.drawerIsOpen ? "end" : "center")
									}}
								>
									{(this.state.drawerIsOpen) ? (
										<KeyboardDoubleArrowLeftIcon />
									) : (
										<KeyboardDoubleArrowRightIcon />
									)}
								</ListItemButton>
							</ListItem>
							<Divider />
							{this.props.tabs.map(({icon, id, text}, index) => (
									<ListItem key={index} disablePadding sx={{"display": "block"}}>
										<ListItemButton
											onClick={() => this.setSelectedTab(id)}
											sx={{
												"minHeight": 48,
												"padding": 0,
												...(this.props.selectedTab === id && {"color": "#64DCBE"})
											}}
										>
											<ListItemIcon sx={{"color": "inherit", "justifyContent": "center"}}>
												{icon}
											</ListItemIcon>
											<ListItemText
												primary={text}
												sx={{...(this.state.drawerIsOpen || {"display": "none"})}}
											/>
										</ListItemButton>
									</ListItem>
								)
							)}
						</List>
					</Drawer>
					<Box component="main" sx={{"flexGrow": 1, "mb": {"xs": 7, "sm": 0}}}>
						<Paper square={true} sx={{"minHeight": "100%", "padding": 2}}>
							<Toolbar />
							{this.props.children}
						</Paper>
					</Box>
					<Paper
						elevation={3}
						sx={{
							"bottom": 0,
							"display": {"sm": "none"},
							"left": 0,
							"position": "fixed",
							"right": 0,
							"zIndex": (theme) => theme.zIndex.drawer-1
						}}
					>
						<BottomNavigation
							onChange={(event, value) => this.setSelectedTab(value)}
							showLabels
							value={this.props.selectedTab}
						>
							{this.props.tabs.map(({icon, id, text}, index) => (
									<BottomNavigationAction icon={icon} key={index} label={text} value={id} />
								)
							)}
						</BottomNavigation>
					</Paper>
				</Box>
			</>
		);
	}
}
