import React from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import Grid2 from '@mui/material/Unstable_Grid2';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestoreIcon from '@mui/icons-material/Restore';

import {secondsToMilliseconds} from 'date-fns';
import PropTypes from 'prop-types';

import {OutlinedTitledBox} from "./OutlinedTitledBox";

export class Chrono extends React.Component {
	static propTypes = {
		"config": PropTypes.object.isRequired,
		"currentPeriod": PropTypes.string.isRequired,
		"reverse": PropTypes.bool.isRequired,
		"sendChronoToApi": PropTypes.func.isRequired,
		"start": PropTypes.number.isRequired,
		"stop": PropTypes.number,
		"updateValue": PropTypes.func.isRequired,
		"value": PropTypes.number.isRequired
	};

	constructor(props) {
		super(props);
		this.applyIncrement = this.applyIncrement.bind(this);
		this.handleStart = this.handleStart.bind(this);
		this.handleStop = this.handleStop.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.loadPeriodConfig = this.loadPeriodConfig.bind(this);
		this.refreshValue = this.refreshValue.bind(this);
		this.startRefreshLoop = this.startRefreshLoop.bind(this);
		this.stopRefreshLoop = this.stopRefreshLoop.bind(this);
		this.toggleDialog = this.toggleDialog.bind(this);
		this.toggleReverse = this.toggleReverse.bind(this);

		this.state = {
			"dialogValue": 0,
			"editDialog": false,
			"periodStart": 0,
			"periodStop": 0,
			"reverse": this.props.reverse,
			"start": this.props.start,
			"stop": this.props.stop
		};
	}

	componentDidMount() {
		this.refreshValue(this.state);
		if (this.state.start === this.state.stop) {
			this.handleReset(true);
		} else if (!this.state.stop) {
			this.setState(this.loadPeriodConfig());
			this.startRefreshLoop();
		}
	}

	componentDidUpdate(prevProps) {
		if (
			(prevProps.start !== this.props.start || prevProps.stop !== this.props.stop) &&
			(this.state.start !== this.props.start || this.state.stop !== this.props.stop)
		) {
			const newState = this.loadPeriodConfig();
			newState.reverse = this.props.reverse;
			newState.start = this.props.start;
			newState.stop = this.props.stop;

			if (newState.stop) {
				this.stopRefreshLoop();
			} else {
				this.startRefreshLoop();
			}
			this.setState(newState, () => this.refreshValue(newState));
			return;
		}
		if (this.props.currentPeriod !== prevProps.currentPeriod) {
			this.handleReset(false);
		}
	}

	applyIncrement(seconds) {
		const newState = this.state;
		if (newState.reverse) {
			newState.start = this.state.start + secondsToMilliseconds(seconds);
		} else {
			newState.start = this.state.start - secondsToMilliseconds(seconds);
		}

		this.setState(newState, () => this.refreshValue(newState));
		this.props.sendChronoToApi(newState);
	}

	formatValue(value) {
		let sign = "";
		if (value < 0) {
			sign = "-";
			value *= -1;
		}
		const minutes = Math.floor(value / 60).toString().padStart(2, "0");
		const seconds = (value % 60).toFixed(1).padStart(4, "0");
		return `${sign}${minutes}:${seconds}`;
	}

	handleStart() {
		const newState = this.state;
		if (this.state.stop) {
			newState.start = this.state.start + (Date.now() - this.state.stop);
			newState.stop = null;
		}

		this.startRefreshLoop();
		this.setState(newState, () => {this.refreshValue(newState);});
		this.props.sendChronoToApi(newState);
	}

	handleStop() {
		const now = Date.now();
		const newState = this.state;
		if (!this.state.stop) {
			newState.stop = now;
			if (
				(newState.reverse && newState.start - newState.stop <= secondsToMilliseconds(newState.periodStop)) ||
				(!newState.reverse && newState.stop - newState.start >= secondsToMilliseconds(newState.periodStop) && newState.periodStop > 0)
			) {
				newState.start = now - secondsToMilliseconds(newState.periodStop);
			}
			if (
				(newState.reverse && newState.start - newState.stop >= secondsToMilliseconds(newState.periodStart)) ||
				(!newState.reverse && newState.stop - newState.start <= secondsToMilliseconds(newState.periodStart))
			) {
				newState.start = now - secondsToMilliseconds(newState.periodStart);
			}
		}

		this.stopRefreshLoop();
		this.setState(newState, () => {this.refreshValue(newState);});
		this.props.sendChronoToApi(newState);
	}

	handleReset(isInit) {
		const now = Date.now();
		const newState = this.loadPeriodConfig();
		if (newState.reverse) {
			newState.start = now + secondsToMilliseconds(newState.periodStart);
			newState.stop = now;
		} else {
			newState.start = now - secondsToMilliseconds(newState.periodStart);
			newState.stop = now;
		}
		newState.dialogValue = 0;
		newState.editDialog = false;

		this.stopRefreshLoop();
		this.setState(newState, () => {this.refreshValue(newState);});
		if (!isInit) {
			this.props.sendChronoToApi(newState);
		}
	}

	loadPeriodConfig() {
		const state = this.state;
		if (this.props.config) {
			if (this.props.config[this.props.currentPeriod]) {
				state.periodStart = this.props.config[this.props.currentPeriod].start;
				state.periodStop = this.props.config[this.props.currentPeriod].stop;
			} else if (this.props.config.default) {
				state.periodStart = this.props.config.default.start;
				state.periodStop = this.props.config.default.stop;
			}
		}
		state.reverse = state.periodStart > state.periodStop;
		return state;
	}

	refreshValue(state) {
		let newValue = 0;
		if (state.stop) {
			newValue = (state.stop - state.start) / 1000;
		} else {
			newValue = (Date.now() - state.start) / 1000;
		}
		if (state.reverse) {
			newValue *= -1;
		}
		if (!state.stop) {
			if (
				(state.reverse && newValue <= state.periodStop) ||
				(!state.reverse && newValue >= state.periodStop && state.periodStop > 0)
			) {
				this.handleStop();
				newValue = state.periodStop;
			}
			if (
				(state.reverse && newValue >= state.periodStart) ||
				(!state.reverse && newValue <= state.periodStart)
			) {
				this.handleStop();
				newValue = state.periodStart;
			}
		}

		this.props.updateValue(newValue);
	}

	startRefreshLoop() {
		this.stopRefreshLoop();
		this.refreshLoop = setInterval(
			() => {this.refreshValue(this.state);},
			100
		);
	}

	stopRefreshLoop() {
		clearInterval(this.refreshLoop);
	}

	toggleDialog() {
		this.setState({"dialogValue": 0, "editDialog": !this.state.editDialog});
	}

	toggleReverse() {
		const newState = this.state;
		newState.reverse = !newState.reverse;
		newState.start = (newState.stop * 2) - newState.start;
		this.setState(newState);
		this.props.sendChronoToApi(newState);
	}

	render() {
		const buttonIncrement = (i) => (
			<Grid2 xs={1}
				style={{"cursor": "pointer", "fontWeight": "bold", "textAlign": "center"}}
				onClick={() => {
					this.setState({"dialogValue": this.state.dialogValue + i});
				}}
			>{i < 0 ? "-" : "+"}</Grid2>
		);
		let dialogValue = "";
		if (this.state.editDialog) {
			dialogValue = this.formatValue(this.props.value + this.state.dialogValue);
		}
		return <>
			<Dialog open={this.state.editDialog}>
				<DialogTitle>Modification du Chronomètre</DialogTitle>
				<DialogContent dividers>
					<Grid2 marginBottom="16px">
						<Grid2 xs={12} variant="outlined" container spacing={0} alignItems="center" justifyContent="center" >
							<Grid2 xs={2}/>
							{buttonIncrement(600)}
							{buttonIncrement(60)}
							<Grid2 xs={1}/>
							{buttonIncrement(10)}
							{buttonIncrement(1)}
							<Grid2 xs={2}/>
							<Grid2 xs={12}/>
							<Grid2 style={{"textAlign": "center"}} xs={2}>{dialogValue.substr(0, dialogValue.length - 7)}</Grid2>
							<Grid2 style={{"textAlign": "center"}} xs={1}>{dialogValue.substr(-7, 1)}</Grid2>
							<Grid2 style={{"textAlign": "center"}} xs={1}>{dialogValue.substr(-6, 1)}</Grid2>
							<Grid2 style={{"textAlign": "center"}} xs={1}>{dialogValue.substr(-5, 1)}</Grid2>
							<Grid2 style={{"textAlign": "center"}} xs={1}>{dialogValue.substr(-4, 1)}</Grid2>
							<Grid2 style={{"textAlign": "center"}} xs={1}>{dialogValue.substr(-3, 1)}</Grid2>
							<Grid2 style={{"textAlign": "center"}} xs={1}>{dialogValue.substr(-2, 1)}</Grid2>
							<Grid2 style={{"textAlign": "center"}} xs={1}>{dialogValue.substr(-1, 1)}</Grid2>
							<Grid2 xs={12}/>
							<Grid2 xs={2}/>
							{buttonIncrement(-600)}
							{buttonIncrement(-60)}
							<Grid2 xs={1}/>
							{buttonIncrement(-10)}
							{buttonIncrement(-1)}
							<Grid2 xs={2}/>
						</Grid2>
					</Grid2>
					<Grid2 container justifyContent="center" spacing={1}>
						<Button variant="outlined" size="small" onClick={() => {
							this.handleReset(false);
						}}><RestoreIcon/> Reset</Button>
					</Grid2>
				</DialogContent>
				<DialogActions>
					<Button size="small" onClick={this.toggleDialog}>Annuler</Button>
					<Button variant="contained" size="small" onClick={() => {
						this.applyIncrement(this.state.dialogValue);
						this.toggleDialog();
					}}>Valider</Button>
				</DialogActions>
			</Dialog>
			<OutlinedTitledBox title="Chrono">
				<Grid2 container justifyContent="center">
					{this.formatValue(this.props.value)}
				</Grid2>
				<Grid2 container justifyContent="center" spacing={1}>
					<Grid2>
						{this.state.stop ? (
							<Button variant="outlined" size="small" onClick={this.handleStart}><PlayArrowIcon/> Start</Button>
						) : (
							<Button variant="outlined" size="small" onClick={this.handleStop}><PauseIcon/> Stop</Button>
						)}
						</Grid2>
					<Grid2>
						<Button onClick={this.toggleDialog}><EditIcon fontSize="small"/></Button>
					</Grid2>
				</Grid2>
			</OutlinedTitledBox>
		</>;
	}
}
