import React from "react";

import PropTypes from "prop-types";

import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button";
import LinearProgress from '@mui/material/LinearProgress';
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";

export class LoadingButton extends React.Component {
	static propTypes = {
		"disabled": PropTypes.bool,
		"onClickWithCallback": PropTypes.func.isRequired,
		"buttonProps": PropTypes.object
	};

	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.state = {
			"alertText": "",
			"isLoading": false,
			"showAlert": false
		};
	}

	onClick() {
		this.setState({"isLoading": true});
		this.props.onClickWithCallback(
			(alertText = "") => this.setState({
				alertText,
				"isLoading": false,
				"showAlert": alertText !== ""
			})
		);
	}

	render() {
		return (<>
			<Button variant="contained" disabled={this.props.disabled} onClick={this.onClick} {...this.props.buttonProps}>
				{this.props.children}
				{this.state.isLoading ? (
					<LinearProgress
						sx={{
							"borderBottomLeftRadius": "4px",
							"borderBottomRightRadius": "4px",
							"bottom": 0,
							"position": "absolute",
							"width": "100%",
						}}
					/>
				) : (
					""
				)}
			</Button>
			<Snackbar
				anchorOrigin={{"vertical": "bottom", "horizontal": "center"}}
				autoHideDuration={4000}
				onClose={() => this.setState({"showAlert": false})}
				open={this.state.showAlert}
				TransitionComponent={Slide}
				TransitionProps={{"direction": "up"}}
			>
				<Alert severity="error" variant="filled">
					{this.state.alertText}
				</Alert>
			</Snackbar>
		</>);
	}
}
