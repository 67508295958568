import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import PropTypes from 'prop-types';

export class HomeLogo extends React.Component {
	static propTypes = {
		"disabled": PropTypes.bool.isRequired
	};

	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.onDialogClose = this.onDialogClose.bind(this);
		this.state = {
			"openDialog": false
		};
	}

	onClick() {
		if (!this.props.disabled) {
			this.setState({"openDialog": true});
		}
	}

	onDialogClose(event) {
		if (event.currentTarget.value === "yes") {
			window.location.href = "/";
		}
		this.setState({"openDialog": false});
	}

	render() {
		return (<>
			<Grid2 sx={{"cursor": "pointer", "display": "flex"}} onClick={this.onClick}>
				<svg width="40" height="40" viewBox="0 0 78.6 60.32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<ellipse
						cx="9.9430742"
						cy="45.59082"
						fill="#64DCBE"
						rx="10.369906"
						ry="10.409901"
						style={{"strokeWidth":"1.00001"}}
						transform="rotate(-44.951386)"
					/>
					<path
						d="m 256.66,71.26 -0.78,0.25 c -4.03,1.27 -7.6,3.55 -10.39,6.64 v 0 c 0,3.35 0.04,20.01 0.04,20.01 3.52,2.02 7.33,3.67 11.43,4.95 4.11,1.28 8.75,1.91 13.93,1.89 5.32,-0.02 9.99,-0.72 14,-2.1 3.97,-1.36 7.67,-3.05 11.12,-5.07 0.04,-0.08 0.08,-0.15 0.13,-0.23 l -0.05,-21.37 v 0 c -2.71,-2.15 -5.75,-3.81 -8.97,-4.89 l -2.41,-0.81 v 0 c -1.56,-15.99 13.35,-8.96 13.35,-8.96 4.15,2.16 8.09,4.74 11.82,7.74 l 0.3,36.6 c -5.28,4.37 -11.08,7.86 -17.4,10.47 -6.31,2.62 -13.57,3.94 -21.76,3.97 -8.05,0.03 -15.27,-1.22 -21.66,-3.76 -6.39,-2.53 -12.3,-5.89 -17.72,-10.08 l -0.06,-33.84 c 2.43,-5.18 11.69,-10.1 11.69,-10.1 5.86,-3.01 13.33,1.85 13.39,8.69 z"
						fill="#64DCBE"
						transform="translate(-231.57995,-60.031006)"
					/>
				</svg>
			</Grid2>
			<Dialog
				onClose={this.onDialogClose}
				open={this.state.openDialog}
			>
				<DialogTitle>
					Confirmation
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Voulez-vous quitter cette page ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button value="no" onClick={this.onDialogClose}>Non</Button>
					<Button value="yes" onClick={this.onDialogClose}>Oui</Button>
				</DialogActions>
			</Dialog>
		</>);
	}
}
