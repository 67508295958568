import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid2 from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import PropTypes from 'prop-types';

import {LoadingButton} from "./LoadingButton";

export class Rules extends React.Component {
	static propTypes = {
		"disabled": PropTypes.bool.isRequired,
		"handleRuleChange": PropTypes.func.isRequired,
		"ruleset": PropTypes.arrayOf(PropTypes.object).isRequired,
		"saveRules": PropTypes.func.isRequired,
		"selectedRules": PropTypes.object.isRequired,
	};

	render() {
		return (<>
			<Grid2 container justifyContent="center" width="100%">
				{this.props.ruleset.map(({desc, key, rules}) => (
					<Paper
						key={key}
						sx={{
							"margin": 2,
							"padding": 2,
							"textAlign": "left",
							"width":{"xs": "100%", "md": "50%"}
						}}
						variant="outlined"
					>
						<FormControl>
							<FormLabel
								id={key+"-radiogroup"}
								sx={{"textAlign": "left", "whiteSpace": "nowrap"}}
							>
								{desc}
							</FormLabel>
							<RadioGroup
								name={key}
								onChange={this.props.handleRuleChange}
								value={this.props.selectedRules[key]}
							>
								{rules.map((rule) => (
									<FormControlLabel
										control={<Radio sx={{"alignSelf": "start"}}/>}
										disabled={this.props.disabled}
										key={rule.value}
										label={rule.text}
										sx={{"padding": "5px"}}
										value={rule.value}
									/>
								))}
							</RadioGroup>
						</FormControl>
					</Paper>
				))}
			</Grid2>
			<Grid2 container justifyContent="center" xs={true} margin={2}>
				<LoadingButton
					disabled={this.props.disabled}
					onClickWithCallback={this.props.saveRules}
				>
					Enregistrer
				</LoadingButton>
			</Grid2>
		</>);
	}
}
